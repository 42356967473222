body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html, body, #root {
  height: 100%;
}

.MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  color: rgb(0, 100, 174);
  background: white;
  border-bottom-left-radius: 2em;
  border-top-left-radius: 2em;
  border-color: white;
}

.MuiTab-wrapper {
  width: 100%;
  display: inline-flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: left;
  margin-left: 1em;
}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: white;
}

.MuiTableCell-root.MuiTableCell-body{
  border-bottom: none;
}

.MuiTypography-body2 {
  font-weight: 600 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
}

.compact.MuiTypography-body2 {
  font-size: 0.9rem !important;
}

.MuiTypography-h5 {
  font-weight: 600 !important;
}
.MuiTypography-h6 {
  font-weight: 600 !important;
}

.ApplicationMenuTabs .MuiButtonBase-root.MuiTab-root{
  width: 210px;
}
.ApplicationMenuTabs .compact .MuiButtonBase-root.MuiTab-root{
  width: 50px;
}

.ApplicationMenuTabs .MuiButtonBase-root.MuiTab-root .MuiTab-wrapper{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
}

.MuiTableRow-root.unattached {
  opacity: 0.8
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background: #1070bd;
  border: 20px none #ffffff;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:active {
  background: #258ee2;
}
::-webkit-scrollbar-track {
  background: #e5f4ff;
  border: none;
  border-radius: 0px;
}

::-webkit-scrollbar-track:active {
  background: #e5f4ff;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

.authentificationField input::placeholder {
  font-size: 14px;
}

.MuiButton-containedSecondary:disabled {
  background-color: #F08080 !important;
  color: #fff !important;
  opacity: 0.6 !important;
}

.MuiButton-containedPrimary:disabled {
    color: #fff !important;
    opacity: 0.6 !important;
    background-color: #0064AE !important;
}